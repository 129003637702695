import React from "react";

const DialogTitle = ({ className = "", children }) => (
  <div
    className={`text-base mt-5 font-semibold text-slate-700 dark:text-slate-100 text-center ${className}`}
  >
    {children}
  </div>
);

export default DialogTitle;
