import getUserInfo from "./user-auth/UserInfo.js";
import { debounce } from "lodash";

const fetchMeetingMinute = (page = 1) => {
  const userInfo = getUserInfo();

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append(
    "Authorization",
    `Basic ${process.env.REACT_APP_HISTORIC_KEY}`
  );

  const email = userInfo.email;

  const requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  return fetch(
    `https://tadeo-services.apps.kloud.rnp.br/historic/meeting_minute/?page=${page}&user__email=${email}`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((history) => {
      return history.results;
    })
    .catch((error) => {
      throw error;
    });
};

const debouncedfetchMeetingMinute = debounce((resolve, reject, page) => {
  fetchMeetingMinute(page).then(resolve).catch(reject);
}, 300);

export const getMeetingMinuteHistory = (page = 1) => {
  return new Promise((resolve, reject) => {
    debouncedfetchMeetingMinute(resolve, reject, page);
  });
};
