import { motion } from "framer-motion";
import PropTypes from "prop-types";

export const TableSearchInput = ({ label, value, onChange }) => (
  <motion.div
    className="mt-8 -mb-6"
    initial={{ opacity: 0, y: +10, height: 0 }}
    animate={{ opacity: 1, y: 0, height: "auto" }}
    exit={{ opacity: 0, y: +10, height: 0 }}
    transition={{ duration: 0.2 }}
    transformTemplate={(transformProps, transform) => {
      return `translate(${transformProps.x ?? 0}px, ${
        transformProps.y ?? 0
      }px) ${transform}`;
    }}
  >
    <div className="relative flex items-center mt-2">
      <input
        id="search"
        name="search"
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="block w-full rounded-md border-0 py-1.5 pr-14 text-slate-900 text-sm dark:text-slate-200 dark:bg-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-700 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 smooth-all"
        placeholder="Buscar..."
      />
    </div>
  </motion.div>
);

TableSearchInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
