import { motion } from "framer-motion";
import { LoadingAnimation } from "../../../../LoadingAnimation/LoadingAnimation";
import PropTypes from "prop-types";

export function MeetingMinuteLoading({ bodyText }) {
  const updating = bodyText === "Atualizando dados...";
  return (
    <motion.div
      className={`absolute flex p-3 ${updating ? "bg-green-50 ring-green-300 dark:bg-[#10482f] dark:ring-green-700" : "bg-white ring-slate-300 dark:bg-slate-900 dark:ring-slate-700"} ring-1 gap-x-3 bottom-3 right-3 min-w-[150px] items-center rounded-md`}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{ duration: 0.3 }}
    >
      {updating ? (
        <LoadingAnimation
          delay={0.2}
          lightColor="#15803d"
          darkColor="#4ade80"
        />
      ) : (
        <LoadingAnimation delay={0.2} />
      )}
      <p
        className={`text-sm font-medium ${updating ? "text-green-700 dark:text-green-400" : "text-slate-700 dark:text-slate-200"}  select-none `}
      >
        {bodyText}
      </p>
    </motion.div>
  );
}

MeetingMinuteLoading.propTypes = {
  bodyText: PropTypes.string.isRequired,
};
