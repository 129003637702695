import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useMeetingMinute } from "../../contexts/MeetingMinuteContext";

const DialogActionButton = (props) => {
  const {
    children,
    className = "primary-btn",
    autoFocus,
    isCancel,
    type = "button",
    isDisabled = false,
  } = props;

  const { closeDialog } = useMeetingMinute();

  const defaultButtonClass = isCancel
    ? "mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50 sm:col-start-1 sm:mt-0"
    : "mt-3 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 sm:mt-0 smooth-colors";

  const finalClassName = `${defaultButtonClass} ${className}`;

  const onClose = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  if ("to" in props && props.to) {
    return (
      <Link
        to={props.to}
        onClick={(event) => {
          if (props.onClick) {
            props.onClick(event);
            onClose();
          }
          if (isCancel) {
            onClose();
          }
        }}
        className={finalClassName}
        {...(autoFocus ? { "data-autofocus": true } : {})}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <button
        type={type}
        onClick={(event) => {
          if (props.onClick) {
            props.onClick(event);
            onClose();
          }
          if (isCancel) {
            onClose();
          }
        }}
        disabled={isDisabled}
        className={finalClassName}
        {...(autoFocus ? { "data-autofocus": true } : {})}
      >
        {children}
      </button>
    );
  }
};
DialogActionButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  isCancel: PropTypes.bool,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

export default DialogActionButton;
