import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useChatContext } from "../../../contexts/ChatContext";
import { useTheme } from "../../../contexts/ThemeProvider";
import UserAvatar from "../../UserAvatar/UserAvatar";
import { capitalizeName } from "../../../server/common-functions/capitalizeName";
import getUserInfo from "../../../server/user-auth/UserInfo";
import { SparklesIcon, LightBulbIcon } from "@heroicons/react/24/outline";

/**
 * Função auxiliar para unir classes CSS dinamicamente.
 *
 * @param {...string} classes - Classes CSS a serem unidas.
 * @returns {string} String com as classes unidas.
 */
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

/**
 * Componente `SidebarOptions` para exibir as opções do usuário no sidebar,
 * incluindo o avatar do usuário, opções para alterar o tema, reportar um problema,
 * e visualizar o changelog.
 *
 * @component
 * @returns {JSX.Element} Componente com opções no sidebar.
 *
 * @example
 * <SidebarOptions />
 */
export default function SidebarOptions() {
  const { setChangelogOpen, setReportForm } = useChatContext();
  const { toggleTheme } = useTheme();
  const userData = getUserInfo();

  return (
    <div className="mt-auto -mx-6 select-none">
      <div className="-mx-2 space-y-1">
        <div className="mx-6 mb-[10px]">
          <div
            className={
              "text-slate-200 cursor-pointer hover:text-white hover:bg-slate-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold transition-colors duration-300"
            }
            onClick={() => setChangelogOpen(true)}
          >
            <SparklesIcon className="w-6 h-6 shrink-0" aria-hidden="true" />
            Histórico de mudanças
          </div>
          <div
            className={
              "text-slate-200 cursor-pointer hover:text-white hover:bg-slate-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold transition-colors duration-300"
            }
            onClick={() => setReportForm(true)}
            // target="_blank"
            // rel="noopener noreferrer"
          >
            <LightBulbIcon className="w-6 h-6 shrink-0" aria-hidden="true" />
            Fale conosco
          </div>
        </div>
      </div>
      {/* Menu do usuário */}

      <Menu
        as="div"
        className="flex items-center px-6 py-3 text-sm font-semibold leading-6 text-white transition-colors duration-300 gap-x-4 hover:bg-slate-800"
      >
        <div className="">
          <Menu.Button className="relative flex text-sm rounded-md ">
            <span className="absolute -inset-1.5" />
            <span className="sr-only">Abrir menu</span>
            {/* Avatar menu */}
            <div className="flex-shrink-0 block group ">
              <div className="flex items-center">
                <div>
                  <UserAvatar size="default" />
                </div>
                <div className="ml-3 text-left">
                  <p className="w-full max-w-[160px] overflow-hidden text-sm font-semibold text-white whitespace-nowrap group-hover:text-slate-900 text-ellipsis">
                    {capitalizeName(userData.fullName)}
                  </p>
                  <p className="text-xs font-medium text-slate-200 group-hover:text-slate-700">
                    Opções
                  </p>
                </div>
              </div>
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute font-medium z-10 mt-[-160px] w-48 origin-top-right rounded-md bg-defaultWhite dark:bg-[#1f2937] py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transition duration-300 ease-in-out">
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={() => toggleTheme()}
                  className={classNames(
                    active ? "bg-slate-100 dark:bg-slate-700" : "",
                    "block px-4 py-2 text-sm text-slate-700 dark:text-slate-100 transition-colors duration-300 cursor-pointer"
                  )}
                >
                  Alterar tema
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/auth"
                  className={classNames(
                    active ? "bg-slate-100 dark:bg-slate-700" : "",
                    "block px-4 py-2 text-sm text-slate-700 dark:text-slate-100 transition-colors duration-300"
                  )}
                >
                  Sair
                </a>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
