import React, { useCallback, useEffect } from "react";
import { motion } from "framer-motion";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useMeetingMinute } from "../../contexts/MeetingMinuteContext";

const DialogRoot = ({ children }) => {
  const { closeDialog } = useMeetingMinute();

  const onClose = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [onClose]);

  return (
    <>
      {/* Backdrop com z-index aumentado e centralizado */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        className="fixed inset-0 z-50 bg-black pointer-events-auto bg-opacity-30 backdrop-blur-md"
        onClick={onClose}
      />

      {/* Container do diálogo centralizado com z-index aumentado */}
      <div className="fixed inset-0 z-50 flex items-end justify-center m-4 pointer-events-none md:items-center md:m-0">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          className="relative w-full max-w-lg p-6 bg-white dark:bg-[#1d2432] rounded-lg shadow-xl pointer-events-auto sm:p-8"
        >
          {/* Botão de fechamento */}
          <div className="absolute top-4 right-4">
            <button
              type="button"
              onClick={onClose}
              className="text-slate-400 bg-white dark:bg-[#1d2432] rounded-md hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 smooth-colors"
            >
              <span className="sr-only">Fechar</span>
              <XMarkIcon aria-hidden="true" className="w-6 h-6" />
            </button>
          </div>
          {children}
        </motion.div>
      </div>
    </>
  );
};

export default DialogRoot;
