import getUserInfo from "./user-auth/UserInfo.js";
import { debounce } from "lodash";

const fetchHistoric = (page = 1) => {
  const userInfo = getUserInfo();

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append(
    "Authorization",
    `Basic ${process.env.REACT_APP_HISTORIC_KEY}`
  );

  const requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  return fetch(
    `https://tadeo-services.apps.kloud.rnp.br/historic/chat/?page=${page}&user__email=${userInfo.email}`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      const transformedData = {
        history: data.results.map((item) => ({
          protocol: item.protocol,
          summary: item.summary,
          pinned: item.pinned,
          historic: item.historic,
          knowledge: item.knowledge,
          type: item.type,
          messages: item.message_chat.map((message) => ({
            id: message.message_id,
            date: message.date,
            role: message.role,
            content: message.content,
            feedback: message.feedback,
          })),
        })),
      };
      return transformedData;
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
};

const debouncedFetchHistoric = debounce((resolve, reject, page) => {
  fetchHistoric(page).then(resolve).catch(reject);
}, 300);

export const getHistoric = (page = 1) => {
  return new Promise((resolve, reject) => {
    debouncedFetchHistoric(resolve, reject, page);
  });
};
