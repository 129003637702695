import React, { Fragment, useState, useEffect, useRef } from "react";
import { AnimatePresence } from "framer-motion";
import { useChatContext } from "../../contexts/ChatContext";
import { Transition } from "@headlessui/react";
import { useSession } from "../../contexts/SessionContext";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useMeetingMinute } from "../../contexts/MeetingMinuteContext";
import DeleteChatPopup from "../../components/Popups/DeleteChatPopup/DeleteChatPopup";
import Dialog from "../../components/Dialog/Dialog";
import RenameChatPopup from "../../components/Popups/RenameChatPopup/RenameChatPopup";
import ChatWindow from "../../components/ChatWindow/ChatWindow";
import Sidebar from "../../components/Sidebar/Sidebar";
import NavBar from "../../components/NavBar/NavBar";
import ChangeLog from "../../components/ChangeLog/ChangeLog";
import ReportForm from "../../components/ReportForm/ReportForm";
import getUserInfo from "../../server/user-auth/UserInfo";
import MobileMessageOptions from "../../components/ChatWindow/Message/MobileMessageOptions/MobileMessageOptions";
import FeedbackDialog from "../../components/FeedbackDialog/FeedbackDialog";

/**
 * MainPage - Componente principal da aplicação que gerencia a exibição do chat, barra lateral,
 * formulários, feedback, changelog e opções de mensagem no celular.
 * Ele também sincroniza o nome de usuário e o ID do Firebase com base nas informações do usuário.
 *
 * @component
 *
 * O `MainPage` gerencia o estado da página principal da aplicação, incluindo a renderização condicional de diversos
 * componentes, como popups, formulários de feedback e o chat, além de realizar interações com o contexto da sessão
 * e do chat para obter e modificar dados relevantes da interface.
 *
 * Ele também utiliza dois `useEffect` hooks para:
 * 1. Obter informações do usuário e atualizar o nome de usuário e o Firebase ID.
 * 2. Gerenciar a exibição de feedback baseado no estado de digitação (se o usuário está digitando ou não).
 *
 * @returns {JSX.Element} - Estrutura completa da página principal com diversos componentes interativos e de contexto.
 *
 * @prop {boolean} mobileNavBarOpen - Indica se a barra de navegação móvel está aberta.
 * @prop {function} setMobileNavBarOpen - Função para alterar o estado de `mobileNavBarOpen`.
 * @prop {string} deletedChatId - ID do chat excluído para exibir o `DeleteChatPopup`.
 * @prop {string} renamedChatId - ID do chat renomeado para exibir o `RenameChatPopup`.
 * @prop {string} renamedChatSummary - Resumo do chat renomeado.
 * @prop {boolean} changelogOpen - Indica se o changelog está aberto.
 * @prop {boolean} reportForm - Indica se o formulário de relatório está aberto.
 * @prop {boolean} showMobileMessagesOptions - Estado das opções de mensagens móveis.
 * @prop {function} setShowMobileMessagesOptions - Função para definir o estado de `showMobileMessagesOptions`.
 * @prop {boolean} isTyping - Indica se o usuário está digitando no chat.
 * @prop {boolean} isSearchDialogOpen - Indica se o diálogo de busca está aberto.
 * @prop {string} sessionId - ID da sessão do usuário.
 * @prop {function} setUsername - Função para definir o nome de usuário no contexto de sessão.
 * @prop {function} setFirebaseUserId - Função para definir o Firebase User ID no contexto de sessão.
 * @prop {boolean} showFeedback - Estado de exibição do popup de feedback.
 * @prop {boolean} shouldShowFeedback - Indica se o feedback deve ser mostrado.
 * @prop {function} handleCloseSidebar - Função para fechar a barra lateral.
 *
 * @example
 * // Exemplo de uso do MainPage:
 * import React from 'react';
 * import MainPage from './MainPage';
 *
 * const App = () => {
 *   return (
 *     <div className="app-container">
 *       <MainPage />
 *     </div>
 *   );
 * };
 *
 * export default App;
 */

function MainPage() {
  const {
    mobileNavBarOpen,
    setMobileNavBarOpen,
    deletedChatId,
    renamedChatId,
    renamedChatSummary,
    changelogOpen,
    reportForm,
    showMobileMessagesOptions,
    setShowMobileMessagesOptions,
    isTyping,
    isSearchDialogOpen,
  } = useChatContext();
  const {
    isDialogOpen,
    closeDialog,
    dialogProps,
    takingTooLong,
    setTakingTooLong,
  } = useMeetingMinute();
  const { sessionId, setUsername, setFirebaseUserId } = useSession();
  const [showFeedback, setShowFeedback] = useState(false);
  const [shouldShowFeedback, setShouldShowFeedback] = useState(false);
  const typingTimerRef = useRef(null);

  const handleCloseSidebar = () => {
    setMobileNavBarOpen(false);
  };

  useEffect(() => {
    const userInfo = getUserInfo();
    const username = userInfo.fullName.replace(/\s/g, ".").toLowerCase();
    setUsername(username);
    setFirebaseUserId(userInfo.uid);

    if (window.clarity) {
      window.clarity("identify", username, sessionId);
    }
  }, [setUsername, setFirebaseUserId, sessionId]);

  useEffect(() => {
    setShouldShowFeedback(true);
    if (isTyping) {
      typingTimerRef.current = setTimeout(() => {
        setShowFeedback(true);
      }, 30000);

      return () => {
        clearTimeout(typingTimerRef.current);
      };
    } else {
      clearTimeout(typingTimerRef.current);
      setShouldShowFeedback(false);
      setTimeout(() => {
        setShowFeedback(false);
      }, 300);
    }
  }, [isTyping]);

  return (
    <div className="relative flex sm:h-[100vh] smallMobile:h-dvh antialiased">
      {deletedChatId && <DeleteChatPopup chatUuid={deletedChatId} />}
      {renamedChatId && (
        <RenameChatPopup
          chatUuid={renamedChatId}
          chatSummary={renamedChatSummary}
        />
      )}
      {showFeedback && (
        <FeedbackDialog
          isWarning={true}
          shouldShow={shouldShowFeedback}
          title={"Aguarde um momento"}
          body={
            "O processamento da resposta está demorando um pouco mais que o comum."
          }
          onClose={() => setShowFeedback(false)}
        />
      )}
      {takingTooLong && (
        <FeedbackDialog
          isWarning={true}
          shouldShow={true}
          title={"Está demorando um pouco"}
          body={
            "Não se preocupe, não é preciso aguardar aqui. Quando estiver pronto, aparecerá no histórico do módulo de reuniões."
          }
          onClose={() => setTakingTooLong(false)}
        />
      )}

      {changelogOpen && <ChangeLog />}
      <AnimatePresence>
        {isDialogOpen && <Dialog {...dialogProps} onCancel={closeDialog} />}
      </AnimatePresence>
      {reportForm && <ReportForm />}
      {showMobileMessagesOptions ? (
        <MobileMessageOptions
          message={showMobileMessagesOptions}
          onClose={() => setShowMobileMessagesOptions(null)}
        />
      ) : null}
      <div
        className={`relative flex sm:h-[100vh] w-full smallMobile:h-dvh transition duration-300 ease-in-out ${
          deletedChatId ||
          renamedChatId ||
          changelogOpen ||
          reportForm ||
          isSearchDialogOpen
            ? "blur-sm"
            : "blur-none"
        }`}
      >
        <Transition
          as={Fragment}
          show={mobileNavBarOpen}
          enter="transition ease-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          className="transform-gpu"
        >
          <div
            className={`horizontalTablet:hidden fixed inset-y-0 left-0 w-[80vw] z-50 flex pointer-events-none`}
          >
            <div className="flex-1 overflow-auto shadow-lg pointer-events-auto z-60">
              <Sidebar />
            </div>

            <XMarkIcon
              className="p-2 pointer-events-auto text-slate-500 size-12 hover:text-slate-700 dark:hover:text-slate-200 focus:outline-none"
              onClick={handleCloseSidebar}
            />
          </div>
        </Transition>
        <div className="sm:flex flex-col text-white smallMobile:hidden w-[300px] md:w-[300px] border-black">
          <Sidebar />
        </div>

        <div
          className={`text-white flex-grow shadow-xl bg-defaultWhite dark:bg-[#0b1120] transition duration-300 ease-in-out ${
            mobileNavBarOpen
              ? "smallMobile:blur-sm"
              : "horizontalTablet:blur-none"
          }`}
        >
          <NavBar onToggle={setMobileNavBarOpen} />
          <div className="h-full" onClick={handleCloseSidebar}>
            <ChatWindow />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
