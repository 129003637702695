import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import { motion } from "framer-motion";
import gifPath from "../../../../../assets/how-to-get-vtt.gif";

export function GetFileTutorial() {
  const markdownContent = `## Como baixar o arquivo VTT de uma gravação do Teams\n\nApós o término de uma reunião gravada e transcrita no Microsoft Teams, siga estes passos para baixar o arquivo VTT da transcrição:\n\n1. **Localize a Gravação da Reunião:**\n   - Abra o **Microsoft Teams** e vá para o chat da reunião ou canal onde ela ocorreu.\n   - Encontre a mensagem que contém a gravação da reunião.\n\n2. **Acesse a Gravação:**\n   - Clique na gravação para abri-la.\n\n3. **Exiba a Transcrição:**\n   - Na janela de reprodução, procure pela opção **"Transcrição"** ou **"Legendas"**.\n   - Se não estiver visível, clique nos três pontos (**...**) para encontrar a opção de exibir a transcrição.\n\n4. **Baixe o Arquivo VTT:**\n   - Com a transcrição aberta, clique novamente nos três pontos (**...**) dentro da seção de transcrição.\n   - Selecione **"Exportar"** ou **"Baixar"**.\n   - Escolha o formato **VTT** para baixar o arquivo.\n\n5. **Salve o Arquivo:**\n   - O arquivo VTT será baixado para a pasta de downloads do seu computador.\n   - Mova-o para o local desejado, se necessário.\n\nPronto! Você agora tem o arquivo VTT da transcrição da sua reunião do Teams.\n![Alt Text](${gifPath})`;

  return (
    <motion.div
      initial={{ opacity: 0, filter: "blur(10px)" }}
      animate={{ opacity: 1, filter: "blur(0px)" }}
      exit={{ opacity: 0, filter: "blur(10px)" }}
      transition={{ duration: 0.2 }}
      className="pb-10"
    >
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkBreaks]}
        components={{
          th: ({ node, ...props }) => (
            <th
              className="px-5 pt-3 mx-2 border-b whitespace-nowrap text-slate-600 dark:text-slate-200 dark:border-slate-600 border-slate-300"
              {...props}
            >
              {props.children}
            </th>
          ),
          td: ({ node, ...props }) => (
            <td
              className="px-5 mx-2 border-b text-slate-600 dark:text-slate-200 dark:border-slate-600 border-slate-300 text-nowrap"
              {...props}
            >
              {props.children}
            </td>
          ),
          h1: ({ node, ...props }) => (
            <h1 className="text-slate-600 dark:text-slate-200" {...props}>
              {props.children}
            </h1>
          ),
          h2: ({ node, ...props }) => (
            <h2
              className={
                "text-base font-semibold leading-7 text-slate-900 dark:text-slate-200"
              }
              {...props}
            >
              {props.children}
            </h2>
          ),
          h3: ({ node, ...props }) => (
            <h3 className="text-slate-600 dark:text-slate-200" {...props}>
              {props.children}
            </h3>
          ),
          p: ({ node, ...props }) => (
            <p
              className="text-sm text-slate-600 dark:text-slate-200"
              {...props}
            >
              {props.children}
            </p>
          ),
          li: ({ node, ordered, ...props }) => (
            <li {...props} className="text-slate-600 dark:text-slate-200">
              {props.children}
            </li>
          ),
          ul: ({ node, ...props }) => (
            <ul {...props} className="pl-5 list-disc">
              {props.children}
            </ul>
          ),
          ol: ({ node, ...props }) => (
            <ol {...props} className="pl-5 list-decimal">
              {props.children}
            </ol>
          ),
          img: ({ node, ...props }) => (
            <img
              {...props}
              className={
                "w-full rounded-xl mt-3 mb-10 select-none pointer-events-none"
              }
              alt={props.alt || ""}
            />
          ),
        }}
      >
        {markdownContent}
      </ReactMarkdown>
    </motion.div>
  );
}
