export function formatParticipants(participants) {
  const names = participants.map((p) => {
    const nameParts = p.nome.split(" ");
    return `${nameParts[0]} ${nameParts[nameParts.length - 1]}`;
  });

  const total = names.length;

  if (total === 0) return "";
  if (total === 1) return names[0];
  if (total === 2) return `${names[0]} e ${names[1]}`;
  if (total === 3) return `${names[0]}, ${names[1]} e ${names[2]}`;

  return `${names[0]}, ${names[1]} e mais ${total - 2} participantes`;
}
