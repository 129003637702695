import { Listbox } from "@headlessui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  CheckIcon,
} from "@heroicons/react/20/solid";
import classNames from "../../utils/classNames";

export const TablePagination = ({
  currentPage,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  totalResults,
  rowsPerPageOptions = [5, 10, 20, 30],
}) => {
  const totalPages = Math.ceil(totalResults / rowsPerPage);

  const startResult = (currentPage - 1) * rowsPerPage + 1;
  const endResult = Math.min(currentPage * rowsPerPage, totalResults);

  let pagesToDisplay = [];

  if (totalPages <= 5) {
    pagesToDisplay = Array.from({ length: totalPages }, (_, i) => i + 1);
  } else if (currentPage <= 2) {
    pagesToDisplay = [1, 2, "right-ellipsis", totalPages];
  } else if (currentPage >= totalPages - 1) {
    pagesToDisplay = [1, "left-ellipsis", totalPages - 1, totalPages];
  } else {
    pagesToDisplay = [
      1,
      "left-ellipsis",
      currentPage,
      "right-ellipsis",
      totalPages,
    ];
  }

  return (
    <div className="flex items-center justify-between py-3 mt-1 select-none border-slate-200">
      {/* Versão mobile */}
      <div className="flex justify-between flex-1 sm:hidden">
        <button
          onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
          disabled={currentPage === 1}
          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold bg-white border rounded-md dark:bg-slate-800 text-slate-600 dark:text-slate-200 border-slate-300 dark:border-slate-700 hover:bg-slate-50 dark:hover:bg-slate-700 disabled:opacity-50 disabled:pointer-events-none smooth-colors"
        >
          Anterior
        </button>
        <button
          onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-semibold bg-white border rounded-md dark:bg-slate-800 text-slate-600 dark:text-slate-200 border-slate-300 dark:border-slate-700 hover:bg-slate-50 dark:hover:bg-slate-700 disabled:opacity-50 disabled:pointer-events-none smooth-colors"
        >
          Próximo
        </button>
      </div>

      {/* Versão desktop */}
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-slate-600 dark:text-slate-200">
            {totalResults === 0 ? "Nenhum resultado" : "Exibindo"}{" "}
            {totalResults !== 0 && (
              <>
                {startResult === endResult ? (
                  "o último registro de "
                ) : totalResults <= rowsPerPage ? (
                  "todos os "
                ) : (
                  <>
                    <span className="font-bold">{startResult}</span> a{" "}
                    <span className="font-bold">{endResult}</span> de{" "}
                  </>
                )}
                <span className="font-bold">{totalResults}</span> resultados
              </>
            )}
          </p>
        </div>
        <div className="flex items-center space-x-6">
          {/* Listbox para alterar o número de resultados por página */}
          <Listbox value={rowsPerPage} onChange={onRowsPerPageChange}>
            <div className="relative">
              <Listbox.Button className="relative w-36 cursor-default rounded-md py-1.5 pl-3 pr-10 text-left text-slate-600 bg-white dark:bg-slate-900 dark:text-slate-200 shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 smooth-all">
                <span className="block truncate">{rowsPerPage} por página</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIcon
                    aria-hidden="true"
                    className="text-slate-400 size-5"
                  />
                </span>
              </Listbox.Button>
              <Listbox.Options className="absolute z-30 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg dark:bg-slate-800 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm smooth-all">
                {rowsPerPageOptions.map((option) => (
                  <Listbox.Option
                    key={option}
                    value={option}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "bg-indigo-600 dark:bg-[#423cbf] text-white"
                          : "text-slate-600 dark:text-slate-200",
                        "cursor-default select-none relative py-2 pl-3 pr-9 smooth-all"
                      )
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {option} por página
                        </span>
                        {selected && (
                          <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 smooth-all">
                            <CheckIcon className="size-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </Listbox>

          {/* Paginação */}
          <nav
            aria-label="Pagination"
            className="inline-flex -space-x-px rounded-md shadow-sm isolate"
          >
            {/* Botão Anterior */}
            <button
              onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
              disabled={currentPage === 1}
              type="button"
              className="relative inline-flex items-center px-2 py-2 bg-white text-slate-400 dark:bg-slate-900 rounded-l-md ring-1 ring-inset ring-slate-300 dark:ring-slate-700 hover:bg-slate-50 dark:hover:bg-slate-700 focus:z-20 disabled:opacity-50 disabled:pointer-events-none smooth-all"
            >
              <span className="sr-only">Anterior</span>
              <ChevronLeftIcon aria-hidden="true" className="size-5" />
            </button>

            {pagesToDisplay.map((item, index) => {
              if (typeof item === "number") {
                return (
                  <button
                    key={index}
                    onClick={() => onPageChange(item)}
                    type="button"
                    className={classNames(
                      item === currentPage
                        ? "z-10 bg-indigo-600 dark:bg-indigo-600/80 pointer-events-none text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        : "text-slate-600 bg-white dark:bg-slate-900 dark:text-slate-200 ring-1 ring-inset ring-slate-300 dark:ring-slate-700 hover:bg-slate-50 dark:hover:bg-slate-700",
                      "relative inline-flex items-center px-4 py-2 text-sm font-semibold smooth-all"
                    )}
                  >
                    {item}
                  </button>
                );
              } else {
                return (
                  <span
                    key={index}
                    className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-slate-600 dark:text-slate-200 ring-1 ring-inset ring-slate-300 dark:ring-slate-700 smooth-all"
                  >
                    ...
                  </span>
                );
              }
            })}

            {/* Botão Próximo */}
            <button
              onClick={() =>
                onPageChange(Math.min(currentPage + 1, totalPages))
              }
              type="button"
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-2 py-2 bg-white text-slate-400 dark:bg-slate-900 rounded-r-md ring-1 ring-inset ring-slate-300 dark:ring-slate-700 hover:bg-slate-50 dark:hover:bg-slate-700 focus:z-20 disabled:opacity-50 disabled:pointer-events-none smooth-all"
            >
              <span className="sr-only">Próximo</span>
              <ChevronRightIcon aria-hidden="true" className="size-5" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};
