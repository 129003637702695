import * as chatListUtils from "./utils/chatListUtils";
import React, { useEffect } from "react";
import { getHistoric } from "../../server/getHistoric";
import { useChatContext } from "../../contexts/ChatContext";
import ChatHistory from "./ChatHistory/ChatHistory";
import ModulesMenu from "./ModulesMenu/ModulesMenu";
import SidebarOptions from "./SidebarOptions/SidebarOptions";
import getUserInfo from "../../server/user-auth/UserInfo";

/**
 * Componente Sidebar
 *
 * Este componente renderiza a barra lateral do aplicativo de chat. Ele exibe:
 * - O título do aplicativo ("Tadeo")
 * - Um menu para iniciar novas conversas ou relatórios
 * - O histórico de chats, agrupado por datas ou status de fixado
 * - Opções de usuário, como reportar um problema ou ver o changelog
 *
 * @returns {JSX.Element} Retorna a barra lateral completa com o menu, histórico de chats e opções do usuário.
 */
export default function Sidebar() {
  const { chatData, setChatData, resetPagination } = useChatContext();

  const userInfo = getUserInfo();
  const userEmail = userInfo.email;

  /**
   * useEffect para buscar o histórico de conversas ao carregar o componente.
   * Quando o histórico é recuperado com sucesso, os dados são salvos no contexto.
   * Caso ocorra um erro, ele é ignorado no momento.
   */
  useEffect(() => {
    getHistoric()
      .then((data) => {
        setChatData(data);
        resetPagination();
      })
      .catch((error) => {});
  }, [setChatData]);

  const sortedGroupedChats = chatListUtils.sortAndGroupChats(chatData.history);

  return (
    <div className="z-10 flex flex-col h-full overflow-hidden bg-slate-900 gap-y-5">
      <div className="flex items-center justify-center h-16 pt-3 text-2xl font-bold select-none shrink-0 text-slate-50">
        {userEmail === "amanda.santos@rnp.br" ? "Tadinho" : "Tadeo"}
      </div>
      <div className="mx-6">
        <ModulesMenu />
      </div>
      <div className="flex flex-col flex-1 mx-6 overflow-hidden">
        <div className="flex-1 mt-3 overflow-hidden">
          <ChatHistory history={sortedGroupedChats} />
        </div>
      </div>
      <div className="mx-6">
        <SidebarOptions />
      </div>
    </div>
  );
}
