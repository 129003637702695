import React from "react";

const DialogIcon = ({ children }) => {
  const getBackgroundColorClass = (children) => {
    let bgColorClass = "bg-slate-100";

    React.Children.forEach(children, (child) => {
      if (React.isValidElement(child)) {
        const className = child.props.className;

        if (className && typeof className === "string") {
          const match = /text-(\w+)-(\d{2,3})/.exec(className);

          if (match) {
            const color = match[1];
            const intensity = parseInt(match[2], 10);
            const newIntensity = Math.max(
              Math.floor((intensity * 0.2) / 100) * 100,
              50
            );
            bgColorClass = `bg-${color}-${newIntensity}`;
          }
        }
      }
    });

    return bgColorClass;
  };

  const bgColorClass = getBackgroundColorClass(children);

  return (
    <div
      className={`flex items-center justify-center size-12 mx-auto rounded-full ${bgColorClass}`}
    >
      {children}
    </div>
  );
};

export default DialogIcon;
