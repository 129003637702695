export const TableHeaderCell = ({ children, variant = "main" }) => {
  let className = "";
  switch (variant) {
    case "main":
      className =
        "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-700 dark:text-slate-200 sm:pl-6";
      break;
    case "hidden":
      className =
        "hidden px-3 py-3.5 text-left text-sm font-semibold text-slate-700 dark:text-slate-200 lg:table-cell";
      break;
    case "action":
      className =
        "relative py-3.5 pl-3 pr-4 sm:pr-6 text-sm text-slate-700 dark:text-slate-200";
      break;
    default:
      className =
        "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate-700 dark:text-slate-200 sm:pl-6";
  }

  return (
    <th scope="col" className={className}>
      {children}
    </th>
  );
};
