import classNames from "../../utils/classNames";

export const TableActionCell = ({
  children,
  isFirstRow = false,
  className,
}) => {
  const defaultClassName = classNames(
    isFirstRow ? "" : "border-t dark:border-slate-700",
    `relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 ${className}`
  );

  return <td className={defaultClassName}>{children}</td>;
};
