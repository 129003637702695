import classNames from "../../utils/classNames";

export const TableHiddenCell = ({ children, isFirstRow = false }) => {
  const className = classNames(
    isFirstRow ? "" : "border-t border-slate-200 dark:border-slate-700",
    "hidden px-3 py-3.5 text-sm lg:table-cell"
  );

  return <td className={className}>{children}</td>;
};
