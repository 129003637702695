import { Link } from "react-router-dom";

export const TableAction = ({ onClick, to, children, className }) => {
  if (onClick && !to) {
    return (
      <button
        type="button"
        onClick={onClick}
        className={`inline-flex items-center rounded-md bg-white dark:bg-slate-900 px-2.5 py-1.5 text-sm font-semibold text-slate-700 dark:text-slate-200 shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-700 hover:bg-slate-50 dark:hover:bg-slate-800 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white smooth-colors ${className}`}
      >
        {children}
      </button>
    );
  } else if (!onClick && to) {
    return (
      <Link
        to={to}
        className={`inline-flex items-center rounded-md bg-white dark:bg-slate-900 px-2.5 py-1.5 text-sm font-semibold text-slate-700 dark:text-slate-200 shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-700 hover:bg-slate-50 dark:hover:bg-slate-800 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white smooth-colors ${className}`}
      >
        {children}
      </Link>
    );
  } else {
    throw new Error(
      "TableAction component requires either onClick or to prop."
    );
  }
};
