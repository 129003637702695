import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import cloud from "d3-cloud";
import _ from "lodash";

const Wordcloud = ({ data, loading = false }) => {
  const svgRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 800, height: 400 });

  const transformDataToCloudFormat = (data) => {
    return Object.entries(data).map(([text, value]) => ({ text, size: value }));
  };

  const transformedData = useMemo(
    () => transformDataToCloudFormat(data),
    [data]
  );

  const handleResize = useCallback(
    _.debounce(() => {
      const width = Math.min(800, window.innerWidth - 20);
      const height = width / 2;
      setDimensions({ width, height });
    }, 200), // Aguarda 200ms após o último evento
    []
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const draw = (words, width, height) => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    svg
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`)
      .selectAll("text")
      .data(words)
      .enter()
      .append("text")
      .style("font-family", "sans-serif")
      .style("fill", () => d3.schemeCategory10[Math.floor(Math.random() * 10)])
      .style("font-size", (d) => `${d.size}px`)
      .attr("text-anchor", "middle")
      .attr("transform", (d) => `translate(${d.x}, ${d.y}) rotate(${d.rotate})`)
      .text((d) => d.text);
  };

  const layoutRef = useRef();

  useEffect(() => {
    if (
      !layoutRef.current ||
      dimensions.width !== layoutRef.current.width ||
      dimensions.height !== layoutRef.current.height
    ) {
      layoutRef.current = { ...dimensions };
    }

    const words = transformedData;

    const layout = cloud()
      .size([dimensions.width, dimensions.height])
      .words(words.map((word) => ({ ...word, size: word.size * 10 })))
      .padding(5)
      .rotate(() => (Math.random() > 0.5 ? 0 : 90))
      .font("sans-serif")
      .fontSize((d) => d.size)
      .on("end", (words) => draw(words, dimensions.width, dimensions.height));

    layout.start();
  }, [transformedData, dimensions]);

  if (loading) {
    return (
      <div
        className="mt-10 rounded-lg animate-pulse bg-slate-300 dark:bg-slate-700"
        style={{
          width: dimensions.width,
          height: dimensions.height,
          minWidth: "100%",
        }}
      ></div>
    );
  }

  return (
    <svg
      ref={svgRef}
      className="pb-10 mt-10 rounded-lg w-full h-auto max-w-[800px] transform-gpu"
    />
  );
};

Wordcloud.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default React.memo(
  Wordcloud,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) &&
    prevProps.loading === nextProps.loading
);
