import React, { useEffect } from "react";
import { useMeetingMinute } from "../../../../../contexts/MeetingMinuteContext";
import { formatDate } from "../../../../../utils/formatDate";
import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { formatParticipants } from "../../../../../utils/formatParticipants";
import deleteMeetingMinute from "../../../../../server/deleteMeetingMinute";
import { motion } from "framer-motion";
import { LoadingAnimation } from "../../../../LoadingAnimation/LoadingAnimation";

export function MinuteHistory() {
  const {
    openDialog,
    fetchMeetingMinute,
    meetingMinuteHistory,
    setMeetingMinuteHistory,
    setMeetingData,
    isFetchingMinuteHistory,

    setCurrentMeetingMinuteId,
  } = useMeetingMinute();

  useEffect(() => {
    fetchMeetingMinute();
  }, []);

  // const handleAddClick = (section, fields) => {
  //   openDialog({
  //     title: `Adicionar item`,
  //     introText:
  //       "Preencha as informações a seguir para adicionar um novo item.",
  //     icon: <DocumentTextIcon className="text-indigo-700 size-6" />,
  //     fields,
  //     initialValues: {},
  //     onSubmit: (newData) => handleDialogSubmit(section, newData),
  //     actionButtons: [
  //       {
  //         label: "Cancelar",
  //         isCancel: true,
  //         onClick: () => console.debug("Cancelar adição"),
  //         className:
  //           "bg-slate-50 text-slate-700 hover:bg-slate-100 dark:bg-[#1d2432] dark:text-slate-50 dark:ring-slate-700 dark:hover:bg-slate-700 smooth-colors",
  //       },
  //       {
  //         label: "Adicionar",
  //         type: "submit",
  //         className: "bg-indigo-600 text-white hover:bg-indigo-500",
  //       },
  //     ],
  //   });
  // };

  if (isFetchingMinuteHistory || meetingMinuteHistory.length === 0) {
    const widths = ["w-full", "w-2/3", "w-3/4", "w-4/5", "w-5/6"];
    return (
      <motion.ul
        className=""
        initial={{ opacity: 0, filter: "blur(10px)" }}
        animate={{ opacity: 1, filter: "blur(0px)" }}
        exit={{ opacity: 0, filter: "blur(10px)" }}
        transition={{ duration: 0.2 }}
      >
        {Array(10)
          .fill(0)
          .map((_, index) => (
            <li
              key={index}
              className="flex flex-wrap items-center justify-between px-3 py-6 rounded-lg cursor-pointer pointer-events-none gap-x-6 gap-y-4 sm:flex-nowrap hover:bg-slate-100 dark:hover:bg-slate-700/50 smooth-colors backdrop-blur-5xl backdrop-filter"
            >
              <div className="w-full animate-pulse">
                <div className="font-semibold text-slate-900 dark:text-slate-300 text-sm/6">
                  <div
                    className={`${widths[Math.floor(Math.random() * widths.length)]} h-4 rounded bg-slate-300 dark:bg-slate-700`}
                  ></div>
                </div>
                <div className="flex items-center mt-1 text-slate-500 dark:text-slate-400 gap-x-2 text-xs/5">
                  <div className="w-1/6 h-4 rounded bg-slate-300 dark:bg-slate-700"></div>
                  <svg
                    viewBox="0 0 2 2"
                    className="size-0.5 fill-current animate-pulse"
                  >
                    <circle r={1} cx={1} cy={1} />
                  </svg>
                  <div className="w-1/3 h-4 rounded bg-slate-300 dark:bg-slate-700"></div>
                </div>
              </div>
            </li>
          ))}
      </motion.ul>
    );
  }

  return (
    <motion.ul
      className=""
      initial={{ opacity: 0, filter: "blur(10px)" }}
      animate={{ opacity: 1, filter: "blur(0px)" }}
      exit={{ opacity: 0, filter: "blur(10px)" }}
      transition={{ duration: 0.2 }}
    >
      {meetingMinuteHistory.map((item) => {
        if (item.meeting_minute.generating) {
          // Renderiza um skeleton específico para itens em "geração"
          return (
            <li
              key={item.id}
              className="flex flex-wrap items-center justify-between px-3 py-6 rounded-lg gap-x-6 gap-y-4 sm:flex-nowrap smooth-colors backdrop-blur-5xl backdrop-filter"
            >
              <LoadingAnimation />
              <div className="w-full">
                <div className="font-semibold select-none text-slate-900 dark:text-slate-300 text-sm/6">
                  Relatório em processamento
                </div>
                <div className="flex items-center mt-1 text-slate-500 dark:text-slate-400 gap-x-2 text-xs/5 animate-pulse">
                  <div className="w-1/6 h-4 rounded bg-slate-300 dark:bg-slate-700"></div>
                  <svg
                    viewBox="0 0 2 2"
                    className="size-0.5 fill-current animate-pulse"
                  >
                    <circle r={1} cx={1} cy={1} />
                  </svg>
                  <div className="w-1/3 h-4 rounded bg-slate-300 dark:bg-slate-700"></div>
                </div>
              </div>
            </li>
          );
        }

        // Renderiza normalmente se não estiver gerando
        return (
          <div
            key={item.id}
            onClick={() => {
              setMeetingData(item.meeting_minute);
              setCurrentMeetingMinuteId(item.id);
            }}
            className="flex flex-wrap items-center justify-between w-full px-3 py-5 rounded-lg cursor-pointer group gap-x-6 gap-y-4 sm:flex-nowrap hover:bg-slate-100 dark:hover:bg-slate-700/50 smooth-colors backdrop-blur-5xl backdrop-filter"
          >
            <div>
              <div className="font-semibold text-slate-900 dark:text-slate-300 text-sm/6">
                <p className="">{item.meeting_minute.titulo}</p>
              </div>
              <div className="flex items-center mt-1 text-slate-500 dark:text-slate-400 gap-x-2 text-xs/5">
                <time dateTime={item.dateTime}>
                  {formatDate(item.generated_date)}
                </time>
                <svg viewBox="0 0 2 2" className="size-0.5 fill-current">
                  <circle r={1} cx={1} cy={1} />
                </svg>
                <p>{formatParticipants(item.meeting_minute.participantes)}</p>
              </div>
            </div>
            <Menu
              as="div"
              className="absolute z-30 inline-block text-left top-2 right-2 sm:relative sm:top-0"
            >
              <div>
                <Menu.Button
                  onClick={(event) => event.stopPropagation()}
                  className="z-30 flex items-center transition-opacity rounded-full opacity-0 text-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-slate-100 group-hover:opacity-100 hover:text-slate-900 smooth-all dark:hover:text-slate-100"
                >
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon aria-hidden="true" className="size-5" />
                </Menu.Button>
              </div>

              <Menu.Items
                transition
                className="absolute right-2 z-50 w-52 -mt-2 transition origin-top-right bg-white dark:bg-[#1f2937] rounded-md shadow-lg pointer-events-auto ring-1 ring-black/5 focus:outline-none"
              >
                <div className="z-50 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={(event) => {
                          event.stopPropagation();

                          deleteMeetingMinute(item.id);

                          setMeetingMinuteHistory((prevHistory) =>
                            prevHistory.filter(
                              (historyItem) => historyItem.id !== item.id
                            )
                          );
                        }}
                        className={`block px-4 py-2 z-50 w-full text-start text-sm ${
                          active
                            ? "bg-slate-100 text-slate-900 dark:bg-[#334155] dark:text-slate-200"
                            : "text-slate-700 dark:text-slate-200"
                        } focus:outline-none`}
                      >
                        Deletar
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Menu>
          </div>
        );
      })}
    </motion.ul>
  );
}
