import FileSaver from "file-saver";

const generateMeetingMinute = async (jsonData, documentName) => {
  try {
    const response = await fetch(
      "https://ata-builder-rnp.hmg.apps.kloud.rnp.br/generate-document",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonData),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const blob = await response.blob();
    FileSaver.saveAs(blob, documentName ?? "ATA de Reunião.docx");
  } catch (error) {
    console.error("Error generating meeting minute:", error);
  }
};

export default generateMeetingMinute;
