import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Routes from "./router";
import { SessionProvider } from "./contexts/SessionContext";
import { ThemeProvider } from "./contexts/ThemeProvider";
import { ChatProvider } from "./contexts/ChatContext";
import { MeetingMinuteProvider } from "./contexts/MeetingMinuteContext";
import webSocketManager from "./server/ws/WebSocketManager";

const customSessionId = uuidv4();

function App() {
  useEffect(() => {
    webSocketManager.connect();
  }, []);

  return (
    <ThemeProvider>
      <SessionProvider sessionId={customSessionId}>
        <MeetingMinuteProvider>
          <ChatProvider>
            <Routes />
          </ChatProvider>
        </MeetingMinuteProvider>
      </SessionProvider>
    </ThemeProvider>
  );
}

export default App;
