import { Menu } from "@headlessui/react";
import classNames from "../../utils/classNames";

export const TableMenuItem = ({ onClick, children }) => (
  <Menu.Item>
    {({ active }) => (
      <button
        onClick={onClick}
        className={classNames(
          active ? "bg-slate-100 text-slate-700" : "text-slate-700",
          "block px-4 py-2 text-sm w-full text-left font-normal smooth-all"
        )}
      >
        {children}
      </button>
    )}
  </Menu.Item>
);
