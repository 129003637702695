import { useChatContext } from "../../../../contexts/ChatContext";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import React, { useState, Fragment, useEffect, forwardRef } from "react";
import { motion } from "framer-motion";
import {
  UserCircleIcon,
  ClipboardDocumentListIcon,
  QuestionMarkCircleIcon,
  ArrowRightOnRectangleIcon,
  ArrowDownCircleIcon,
} from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon as SecondaryMagnifyingGlassIcon } from "@heroicons/react/20/solid";

/**
 * Componente SearchField.
 *
 * @component
 *
 * @description
 * O componente `SearchField` fornece uma interface de busca que permite ao usuário buscar por projetos, responsáveis ou programas em uma lista fornecida. O campo de busca exibe sugestões à medida que o usuário digita e realiza filtragens em tempo real. O componente é altamente personalizável, permitindo buscar em diferentes listas com base no contexto.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {string} props.searchIn - Define em qual lista realizar a busca. Pode ser "persons", "projects" ou "programs".
 * @param {Function} props.onChange - Função chamada quando o valor do campo de entrada é alterado.
 * @param {Object} rest - Outras propriedades que podem ser passadas ao campo de entrada.
 *
 * @returns {JSX.Element} O componente que renderiza um campo de busca interativo.
 *
 * @example
 * const handleSearchChange = (event) => console.log(event.target.value);
 * return <SearchField searchIn="projects" onChange={handleSearchChange} />;
 */
const SearchField = forwardRef(({ searchIn, onChange, ...rest }, ref) => {
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [visibleItems, setVisibleItems] = useState(30);
  const { setIsSearchDialogOpen, projectNames, programNames, responsibles } =
    useChatContext();

  const items =
    searchIn === "persons"
      ? responsibles
      : searchIn === "projects"
        ? projectNames
        : programNames;

  useEffect(() => {
    setIsSearchDialogOpen(open);
  }, [open, setIsSearchDialogOpen]);

  useEffect(() => {
    setQuery("");
    setAdditionalInfo("");
  }, [searchIn]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setAdditionalInfo(value);
    setQuery(value);

    if (onChange) {
      onChange(e);
    }
    if (!open) {
      setOpen(true);
    }
  };

  const handleInputClick = () => {
    if (!open) {
      setOpen(true);
    }
  };

  const handleSelect = (item) => {
    if (item) {
      setAdditionalInfo(item.name);
      setOpen(false);
      setQuery("");
      if (onChange) {
        onChange({ target: { value: item.name } });
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setQuery("");
    setAdditionalInfo("");
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const scrollPosition = scrollTop + clientHeight;
    const scrollThreshold = scrollHeight * 0.6;

    if (scrollPosition > scrollThreshold) {
      setVisibleItems((prevVisibleItems) => prevVisibleItems + 30);
    }
  };

  const filteredItems =
    query === ""
      ? []
      : items.filter((item) => {
          const normalizeString = (str) =>
            str
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .trim()
              .replace(/[-_]/g, " ")
              .replace(/[()]/g, "");

          const hasDiacriticalMarks = (str) =>
            /[áàâãäéèêëíìîïóòôõöúùûüçñ]/i.test(str);

          const normalizedQuery = normalizeString(query);
          const normalizedName = normalizeString(item.name);

          if (hasDiacriticalMarks(query)) {
            return query
              .toLowerCase()
              .split(" ")
              .every((word) => item.name.toLowerCase().includes(word));
          }

          return normalizedQuery
            .split(" ")
            .every((word) => normalizedName.includes(word));
        });

  const itemsToDisplay = filteredItems.slice(0, visibleItems);

  return (
    <>
      <div className="default-report-group">
        <label htmlFor="item-name" className="default-report-input-label">
          Nome do{" "}
          {searchIn === "persons"
            ? "responsável"
            : searchIn === "projects"
              ? "projeto"
              : searchIn === "programs"
                ? "programa"
                : "item"}
        </label>
        <input
          {...rest}
          ref={ref}
          type="text"
          value={additionalInfo}
          onChange={handleInputChange}
          onClick={handleInputClick}
          className="default-report-input bg-defaultWhite dark:bg-slate-900"
          placeholder={
            searchIn === "persons"
              ? "Lucas Ornelas"
              : searchIn === "projects"
                ? "Infovia TO Gurupi Brasília"
                : searchIn === "programs"
                  ? "Programa Infovia AL"
                  : "Nome do item"
          }
          autoComplete="off"
          spellCheck="false"
        />
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 transition-opacity bg-transparent"
              onClick={handleClose}
            />
          </Transition.Child>

          <div className="flex items-start justify-center min-h-screen p-4 sm:items-start sm:p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-2xl overflow-hidden transition-all transform shadow-2xl bg-defaultWhite dark:bg-slate-900 rounded-xl ring-1 ring-black ring-opacity-5">
                <Combobox as="div" className="relative" onChange={handleSelect}>
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute left-4 top-3.5 size-5 text-slate-400 dark:text-slate-200"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      autoFocus
                      className="w-full h-12 pr-4 bg-transparent border-0 text-slate-900 dark:text-slate-100 pl-11 placeholder:text-slate-400 focus:ring-0 sm:text-sm"
                      placeholder={`Buscar por ${searchIn === "projects" ? "projeto" : searchIn === "persons" ? "responsável" : searchIn === "programs" ? "programa" : "item"}...`}
                      value={query}
                      onChange={(event) => {
                        setQuery(event.target.value);
                        setVisibleItems(30);
                      }}
                    />
                  </div>

                  {(query === "" || itemsToDisplay.length > 0) && (
                    <Combobox.Options
                      static
                      as="ul"
                      className="overflow-y-auto divide-y divide-slate-100 max-h-80 scroll-py-2"
                      onScroll={handleScroll}
                    >
                      <li className="p-2">
                        {query === "" && (
                          <>
                            <motion.div
                              className="px-6 py-12 text-center sm:px-14"
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ duration: 0.7 }}
                            >
                              <SecondaryMagnifyingGlassIcon
                                className="mx-auto text-slate-400 size-6 dark:text-slate-200"
                                aria-hidden="true"
                              />
                              <motion.p
                                className="mt-4 text-sm select-none text-slate-900 dark:text-slate-200"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1 }}
                              >
                                Comece a digitar para buscar por um{" "}
                                {searchIn === "projects"
                                  ? "projeto"
                                  : searchIn === "persons"
                                    ? "responsável"
                                    : searchIn === "programs"
                                      ? "programa"
                                      : "item"}
                                .
                              </motion.p>
                            </motion.div>
                            {/* <div className="relative">
                              <div
                                aria-hidden="true"
                                className="absolute inset-0 flex items-center"
                              >
                                <div className="w-full border-t border-slate-300 dark:border-slate-600" />
                              </div>
                              <div className="relative flex justify-start">
                                <span className="pl-2 pr-3 text-sm font-semibold leading-6 text-slate-500 bg-white select-none dark:bg-slate-900 dark:text-slate-300">
                                  Recentes
                                </span>
                              </div>
                            </div> */}
                          </>
                        )}
                        <ul className="text-sm text-slate-700">
                          {(query === "" ? [] || "" : itemsToDisplay).map(
                            (item, index) => (
                              <motion.div
                                key={item.id}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                  duration: 0.7,
                                }}
                              >
                                <Combobox.Option
                                  as="li"
                                  key={item.id}
                                  value={item}
                                  className={({ active }) =>
                                    `group flex select-none items-center rounded-md px-3 py-2 transition-colors duration-300 cursor-pointer ${
                                      active
                                        ? "bg-slate-100 dark:bg-slate-800 dark:text-white text-slate-800"
                                        : "hover:bg-slate-100 dark:hover:bg-slate-800"
                                    }`
                                  }
                                >
                                  {searchIn === "persons" ? (
                                    <UserCircleIcon
                                      className="size-6 flex-none text-slate-400 dark:text-slate-300 group-data-[focus]:text-white"
                                      aria-hidden="true"
                                    />
                                  ) : searchIn === "projects" ? (
                                    <ClipboardDocumentListIcon
                                      className="size-6 flex-none text-slate-400 dark:text-slate-300 group-data-[focus]:text-white"
                                      aria-hidden="true"
                                    />
                                  ) : searchIn === "programs" ? (
                                    <ClipboardDocumentListIcon
                                      className="size-6 flex-none text-slate-400 dark:text-slate-300 group-data-[focus]:text-white"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <QuestionMarkCircleIcon
                                      className="size-6 flex-none text-slate-400 dark:text-slate-300 group-data-[focus]:text-white"
                                      aria-hidden="true"
                                    />
                                  )}
                                  <span className="flex-auto ml-3 truncate dark:text-slate-200">
                                    {item.name}
                                  </span>
                                  <div
                                    className={`flex space-x-1 ${query !== "" && "hidden"}`}
                                  >
                                    <ArrowDownCircleIcon
                                      className="size-6 flex-none text-slate-400 dark:text-slate-300 group-data-[focus]:text-white hover:text-slate-600 dark:hover:text-slate-100 transition-colors duration-300"
                                      aria-hidden="true"
                                    />
                                    <ArrowRightOnRectangleIcon
                                      className="size-6 flex-none text-slate-400 dark:text-slate-300 group-data-[focus]:text-white hover:text-slate-600 dark:hover:text-slate-100 transition-colors duration-300"
                                      aria-hidden="true"
                                    />
                                  </div>
                                </Combobox.Option>
                              </motion.div>
                            )
                          )}
                        </ul>
                      </li>
                    </Combobox.Options>
                  )}

                  {query !== "" && itemsToDisplay.length === 0 && (
                    <motion.div
                      className="px-6 text-center py-14 sm:px-14"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.7 }}
                    >
                      {searchIn === "persons" ? (
                        <UserCircleIcon
                          className="mx-auto text-slate-400 size-6 dark:text-slate-200"
                          aria-hidden="true"
                        />
                      ) : searchIn === "projects" ? (
                        <ClipboardDocumentListIcon
                          className="mx-auto text-slate-400 size-6 dark:text-slate-200"
                          aria-hidden="true"
                        />
                      ) : (
                        <QuestionMarkCircleIcon
                          className="mx-auto text-slate-400 size-6 dark:text-slate-200"
                          aria-hidden="true"
                        />
                      )}
                      <motion.p
                        className="mt-4 text-sm text-slate-900 dark:text-slate-200"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                      >
                        Nós não conseguimos achar{" "}
                        {searchIn === "persons"
                          ? "ninguém"
                          : searchIn === "projects"
                            ? "nenhum projeto"
                            : "nenhum item"}{" "}
                        com o nome "{query}". Tente buscar por outro nome.
                      </motion.p>
                    </motion.div>
                  )}
                  <div className="hidden verticalTablet:flex flex-wrap items-center bg-slate-50 dark:bg-slate-800 dark:text-slate-300 px-4 py-2.5 text-xs text-slate-700 select-none">
                    Você também pode utilizar as teclas{" "}
                    {itemsToDisplay.length !== 1 && (
                      <>
                        <kbd
                          className={
                            "mx-1 flex size-5 items-center justify-center rounded border bg-defaultWhite dark:bg-slate-800 dark:text-slate-300 font-semibold sm:mx-2 border-slate-400 text-slate-900"
                          }
                        >
                          &darr;
                        </kbd>{" "}
                        <span className="hidden sm:inline">e</span>
                        <kbd
                          className={
                            "mx-1 flex size-5 items-center justify-center rounded border bg-defaultWhite dark:bg-slate-800 dark:text-slate-300 font-semibold sm:mx-2 border-slate-400 text-slate-900"
                          }
                        >
                          &uarr;
                        </kbd>{" "}
                        para navegar e
                      </>
                    )}
                    <kbd
                      className={
                        "mx-1 flex size-5 items-center justify-center rounded border bg-defaultWhite dark:bg-slate-800 dark:text-slate-300 font-semibold sm:mx-2 border-slate-400 text-slate-900 px-[25px]"
                      }
                    >
                      Enter
                    </kbd>
                    <span className="hidden sm:inline">ou</span>
                    <kbd
                      className={
                        "mx-1 flex size-5 items-center justify-center rounded border bg-defaultWhite dark:bg-slate-800 dark:text-slate-300 font-semibold sm:mx-2 border-slate-400 text-slate-900 px-[17px]"
                      }
                    >
                      Tab
                    </kbd>{" "}
                    para selecionar.
                    <div className="absolute text-slate-400 right-3 dark:text-slate-400">
                      {filteredItems.length > 0 ? filteredItems.length : ""}
                    </div>
                  </div>
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
});

export default SearchField;
