import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

export const TableSearchButton = ({ onClick, isSearchVisible }) => (
  <button
    type="button"
    onClick={onClick}
    className={`inline-flex items-center mx-2 bg-white dark:bg-slate-900 rounded-full text-slate-400 dark:text-slate-300 hover:text-slate-500 dark:hover:text-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 smooth-all ${
      isSearchVisible ? "!text-indigo-600 dark:!text-indigo-400" : ""
    }`}
  >
    <MagnifyingGlassIcon className="size-5" aria-hidden="true" />
    <span className="sr-only">Search</span>
  </button>
);
