import { motion } from "framer-motion";
import PropTypes from "prop-types";

export function LoadingAnimation({ delay, lightColor, darkColor }) {
  return (
    <>
      <motion.div
        dangerouslySetInnerHTML={{
          __html: `
              <lord-icon
                src="https://cdn.lordicon.com/gkryirhd.json"
                trigger="loop"
                state="loop-snake-alt"
                colors="primary:${darkColor ?? "#f8fafc"}"
                style="width:30px;height:30px">
              </lord-icon>
            `,
        }}
        className="hidden dark:flex size-[30px] z-0"
        initial={{ opacity: 0, filter: "blur(20px)" }}
        animate={{
          opacity: 1,
          filter: "blur(0px)",
        }}
        exit={{ opacity: 0, filter: "blur(20px)" }}
        transition={{ duration: 1, delay: delay || 0 }}
      />
      <motion.div
        dangerouslySetInnerHTML={{
          __html: `
              <lord-icon
                src="https://cdn.lordicon.com/gkryirhd.json"
                trigger="loop"
                state="loop-snake-alt"
                colors="primary:${lightColor ?? "#64748b"}"
                style="width:30px;height:30px">
              </lord-icon>
            `,
        }}
        className="flex dark:hidden size-[30px] z-0"
        initial={{ opacity: 0, filter: "blur(20px)" }}
        animate={{
          opacity: 1,
          filter: "blur(0px)",
        }}
        exit={{ opacity: 0, filter: "blur(20px)" }}
        transition={{ duration: 1, delay: delay || 0 }}
      />
    </>
  );
}

LoadingAnimation.propTypes = {
  delay: PropTypes.number,
  lightColor: PropTypes.string,
  darkColor: PropTypes.string,
};
