import React, { useState, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Table } from "./index";
import { AnimatePresence } from "framer-motion";
import { PlusIcon } from "@heroicons/react/24/outline";

export const DataTable = ({
  columns,
  data,
  actions,
  searchable = false,
  addable = false,
  pagination = { rowsPerPageOptions: [5, 10, 20], defaultRowsPerPage: 5 },
  onClickAdd,
  isLoading = false,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    pagination.defaultRowsPerPage || 5
  );

  // Referência para o topo da tabela
  const tableTopRef = useRef(null);

  // Função para alternar a visibilidade da busca
  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
    if (isSearchVisible) {
      setSearchQuery("");
    }
  };

  // Filtrar os dados com base na query de busca
  const filteredData = useMemo(() => {
    if (!searchQuery) return data;
    return data.filter((item) =>
      columns.some((column) => {
        const value = item[column.field];
        return (
          value &&
          value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
      })
    );
  }, [searchQuery, data, columns]);

  // Paginar os dados
  const totalResults = filteredData.length;
  const paginatedData = useMemo(() => {
    if (!pagination) return filteredData;
    const startIndex = (currentPage - 1) * rowsPerPage;
    return filteredData.slice(startIndex, startIndex + rowsPerPage);
  }, [filteredData, currentPage, rowsPerPage]);

  // Funções de paginação
  const handleRowsPerPageChange = (rows) => {
    setRowsPerPage(rows);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Rolar suavemente para o topo da tabela
    if (tableTopRef.current) {
      tableTopRef.current.scrollIntoView();
      window.scrollBy({ top: -100 });
    }
  };

  const renderSkeletonRows = () => {
    const skeletonRowCount = Math.floor(Math.random() * 3) + 3; // Random number between 3 and 5
    return Array.from({ length: skeletonRowCount }).map((_, rowIndex) => (
      <Table.Row key={`skeleton-${rowIndex}`}>
        {columns.map((_, colIndex) => (
          <Table.MainCell key={`skeleton-cell-${rowIndex}-${colIndex}`}>
            <div
              className={`w-full h-3.5 ${colIndex === 0 ? "" : "-ml-3"} bg-slate-300 dark:bg-slate-700 rounded-sm animate-pulse`}
            />
          </Table.MainCell>
        ))}
        {actions && actions.length > 0 && (
          <Table.ActionCell>
            <div className="w-16 h-4 ml-auto rounded-sm bg-slate-300 dark:bg-slate-700 animate-pulse" />
          </Table.ActionCell>
        )}
      </Table.Row>
    ));
  };

  return (
    <>
      <div ref={tableTopRef} /> {/* Referência ao topo da tabela */}
      <AnimatePresence>
        {isSearchVisible && (
          <Table.SearchInput
            label="Buscar"
            value={searchQuery}
            onChange={(value) => {
              setSearchQuery(value);
              setCurrentPage(1);
            }}
          />
        )}
      </AnimatePresence>
      <Table.Root>
        <Table.Head>
          {columns.map((column, index) => (
            <Table.HeaderCell key={index} variant={column.variant || "hidden"}>
              {column.title}
            </Table.HeaderCell>
          ))}

          <Table.HeaderCell variant="action">
            {searchable && !isLoading && (
              <div className="absolute top-3.5 right-[20px]">
                <Table.SearchButton
                  onClick={toggleSearch}
                  isSearchVisible={isSearchVisible}
                />
              </div>
            )}
            {addable && !isLoading && (
              <div className="absolute top-[13px] right-[45px] ">
                <PlusIcon
                  className="inline-flex items-center mx-2 bg-white rounded-full cursor-pointer size-[22px] dark:bg-slate-900 text-slate-400 dark:text-slate-300 hover:text-slate-500 dark:hover:text-slate-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 smooth-all"
                  onClick={onClickAdd}
                />
              </div>
            )}
          </Table.HeaderCell>
        </Table.Head>
        <Table.Body>
          {isLoading
            ? renderSkeletonRows()
            : paginatedData.map((row, rowIndex) => (
                <Table.Row key={rowIndex}>
                  {columns.map((column, colIndex) => {
                    const CellComponent =
                      column.variant === "main"
                        ? Table.MainCell
                        : Table.HiddenCell;
                    return (
                      <CellComponent key={colIndex} isFirstRow={rowIndex === 0}>
                        {row[column.field]}
                      </CellComponent>
                    );
                  })}
                  {actions && actions.length > 0 ? (
                    <Table.ActionCell
                      className="space-x-2"
                      isFirstRow={rowIndex === 0}
                    >
                      {actions.map((action, actionIndex) => (
                        <Table.Action
                          key={actionIndex}
                          onClick={() => action.onClick(row, rowIndex)}
                        >
                          {action.label}
                        </Table.Action>
                      ))}
                    </Table.ActionCell>
                  ) : (
                    <Table.ActionCell
                      className="space-x-2"
                      isFirstRow={rowIndex === 0}
                    ></Table.ActionCell>
                  )}
                </Table.Row>
              ))}
        </Table.Body>
      </Table.Root>
      {pagination && !isLoading && (
        <Table.Pagination
          currentPage={currentPage}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          totalResults={totalResults}
          rowsPerPageOptions={pagination.rowsPerPageOptions}
        />
      )}
    </>
  );
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      variant: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  searchable: PropTypes.bool,
  addable: PropTypes.bool,
  pagination: PropTypes.shape({
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    defaultRowsPerPage: PropTypes.number,
  }),
  onClickAdd: PropTypes.func,
};
