import React, { useMemo } from "react";

const renderSkeletonRows = (rows) => {
  const widths = ["w-full", "w-1/2", "w-1/3", "w-2/3", "w-3/4", "w-5/6"];

  const skeletonCount =
    rows <= 2 ? rows : Math.floor(Math.random() * (rows - 1)) + 2;

  return (
    <>
      {Array.from({ length: skeletonCount }, (_, index) => {
        const randomWidth = widths[Math.floor(Math.random() * widths.length)];

        return (
          <div
            key={index}
            className={`h-3.5 ${randomWidth} bg-slate-300 dark:bg-slate-700 rounded-sm mt-2 mb-2 animate-pulse`}
          />
        );
      })}
    </>
  );
};

const SkeletonRows = ({ rows }) => {
  const memoizedSkeletonRows = useMemo(() => renderSkeletonRows(rows), [rows]);
  return memoizedSkeletonRows;
};

export default SkeletonRows;
