import React, { useState } from "react";
import Slideover from "../Slideover/Slideover";
import FileUpload from "../FileUpload/FileUpload";
import TextArea from "./TextArea/TextArea";
import { useSession } from "../../contexts/SessionContext";
import { sendClickUpReport } from "../../server/sendClickUpReport";
import SuccessDialog from "./SuccessDialog/SuccessDialog";
import ErrorDialog from "./ErrorDialog/ErrorDialog";

/**
 * Componente de formulário para reportar problemas ou sugerir melhorias.
 *
 * @component
 * @returns {JSX.Element} O formulário de relatório de problemas e melhorias.
 */
export default function ReportForm() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const { sessionId, username, firebaseUserId } = useSession();
  const [comment, setComment] = useState("");
  const commentMinLength = 20;
  const commentMaxLength = 1500;

  /**
   * Manipula a mudança nos arquivos selecionados.
   *
   * @param {Array} newFiles - Lista de arquivos selecionados.
   */
  const handleFileChange = (newFiles) => {
    setFiles(newFiles);
  };

  /**
   * Manipula o envio do formulário.
   *
   * @param {Event} event - Evento de submissão do formulário.
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    sendClickUpReport(files, username, firebaseUserId, sessionId, comment)
      .then((response) => {
        if (response.statusCode === 201) {
          setLoading(false);
          setFiles([]);
          setComment("");
          setShowSuccessDialog(true);
        } else {
          setLoading(false);
          setShowErrorDialog(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowErrorDialog(true);
      });
  };

  return (
    <Slideover title={"Fale Conosco"}>
      {showSuccessDialog && (
        <SuccessDialog onClose={() => setShowSuccessDialog(false)} />
      )}
      {showErrorDialog && (
        <ErrorDialog onClose={() => setShowErrorDialog(false)} />
      )}
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <div className="border-b border-slate-900/10">
            <p className="mt-1 text-sm leading-6 select-none text-slate-600 dark:text-slate-200">
              Formulário para reportar problemas ou sugerir melhorias.
            </p>
            <TextArea
              comment={comment}
              setComment={setComment}
              commentMaxLength={commentMaxLength}
              commentMinLength={commentMinLength}
            />
            <FileUpload
              onFileSelect={handleFileChange}
              externalFiles={files}
              label="Gostaria de anexar alguma evidência?"
              validTypes={["png", "jpg", "jpeg", "pdf", "docx", "mp4", "heic"]}
            />
          </div>
        </div>
        <div className="flex items-center justify-end mt-6 gap-x-6">
          <button
            type="submit"
            disabled={comment.length < commentMinLength || loading}
            className="btn-primary"
          >
            {loading ? "Enviando..." : "Enviar"}
          </button>
        </div>
      </form>
    </Slideover>
  );
}
