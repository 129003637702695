import React, { createContext, useContext, useState, useMemo } from "react";
import PropTypes from "prop-types";

/**
 * Criação do contexto de sessão.
 * Este contexto será utilizado para gerenciar informações de sessão e usuário dentro da aplicação.
 */
const SessionContext = createContext(null);

/**
 * Provedor de contexto de sessão.
 * Fornece informações de sessão e funções para atualizar os estados relacionados ao usuário.
 *
 * @param {object} props - As propriedades do componente.
 * @param {ReactNode} props.children - Os componentes filhos que terão acesso ao contexto.
 * @param {string} props.sessionId - O ID da sessão atual.
 *
 * @returns {JSX.Element} O provedor de contexto de sessão.
 */
export const SessionProvider = ({ children, sessionId }) => {
  const [username, setUsername] = useState("");
  const [firebaseUserId, setFirebaseUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const contextValue = useMemo(
    () => ({
      sessionId,
      username,
      setUsername,
      firebaseUserId,
      setFirebaseUserId,
      userEmail,
      setUserEmail,
    }),
    [sessionId, username, firebaseUserId, userEmail]
  );

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.node.isRequired,
  sessionId: PropTypes.string.isRequired,
};

export const useSession = () => useContext(SessionContext);
