import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import DialogRoot from "./DialogRoot";
import DialogIcon from "./DialogIcon";
import DialogTitle from "./DialogTitle";
import DialogContent from "./DialogContent";
import DialogActions from "./DialogActions";
import DialogActionButton from "./DialogActionButton";
import PropTypes from "prop-types";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const Dialog = ({
  title,
  introText,
  icon,
  fields,
  initialValues,
  onCancel,
  onSubmit,
  actionButtons,
}) => {
  const validationSchema = useMemo(() => {
    const shape = {};
    fields.forEach((field) => {
      if (field.required) {
        shape[field.name] = z
          .string()
          .nonempty(`O campo "${field.label.toLowerCase()}" é obrigatório`);
      } else {
        shape[field.name] = z.string().optional();
      }
    });
    return z.object(shape);
  }, [fields]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: zodResolver(validationSchema),
  });

  // Encapsula o onSubmit para fechar o diálogo automaticamente
  const handleFormSubmit = async (data) => {
    await onSubmit(data); // Executa o onSubmit passado como prop
    onCancel(); // Fecha o diálogo após o submit bem-sucedido
  };

  const renderField = (name, label, value, required) => {
    const isTextArea = typeof value === "string" && value.length > 64;
    const InputComponent = isTextArea ? "textarea" : "input";

    return (
      <div key={name} className="min-w-full mt-3 sm:col-span-4">
        <div className="default-input-ring dark:!bg-[#1d2432]">
          <label htmlFor={name} className="default-input-label">
            {label} {required && <span className="text-red-500">*</span>}
          </label>
          <InputComponent
            id={name}
            {...register(name)}
            type={isTextArea ? undefined : "text"}
            placeholder={`Digite ${label.toLowerCase()}`}
            className={`default-input dark:!bg-[#1d2432] ${
              errors[name] ? "border-red-500" : "border-slate-300"
            }`}
            rows={isTextArea ? 4 : undefined}
          />
        </div>
        {errors[name] && (
          <p className="mt-1 text-xs text-red-500">{errors[name]?.message}</p>
        )}
      </div>
    );
  };

  return (
    <DialogRoot>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div>
          <DialogIcon>{icon}</DialogIcon>
        </div>
        <div className="mt-3 sm:mt-0 sm:text-left">
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <p className="mb-4 dark:text-slate-300">{introText}</p>
            <div className="space-y-5">
              {fields.map(({ name, label, value, required }) =>
                renderField(name, label, value, required)
              )}
            </div>
          </DialogContent>
        </div>
        <DialogActions>
          {actionButtons.map((button, index) => {
            const isSubmitButton = button.type === "submit";

            return (
              <DialogActionButton
                key={index}
                type={button.type || "button"}
                onClick={
                  !isSubmitButton ? button.onClick || onCancel : undefined
                }
                isCancel={button.isCancel}
                className={button.className}
              >
                {button.label}
              </DialogActionButton>
            );
          })}
        </DialogActions>
      </form>
    </DialogRoot>
  );
};

export default Dialog;

Dialog.propTypes = {
  title: PropTypes.string.isRequired,
  introText: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      required: PropTypes.bool,
    })
  ).isRequired,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      type: PropTypes.string,
      onClick: PropTypes.func,
      isCancel: PropTypes.bool,
      className: PropTypes.string,
    })
  ).isRequired,
};
