import PropTypes from "prop-types";

export const TableRoot = ({ children }) => (
  <div className="mt-10 bg-white rounded-lg dark:bg-slate-900 ring-1 ring-slate-300 dark:ring-slate-700 sm:mx-0 text-slate-700 dark:text-slate-200">
    <table className="min-w-full divide-y divide-slate-300 dark:divide-slate-700">
      {children}
    </table>
  </div>
);

TableRoot.propTypes = {
  children: PropTypes.node.isRequired,
};
