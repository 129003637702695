import classNames from "../../utils/classNames";

export const TableMainCell = ({ children, isFirstRow = false }) => {
  const className = classNames(
    isFirstRow ? "" : "border-t dark:border-slate-700",
    "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
  );

  return <td className={className}>{children}</td>;
};
