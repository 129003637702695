import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

/**
 * ThemeProvider - Componente que gerencia o tema da aplicação (claro ou escuro) e armazena o valor no localStorage.
 *
 * @component
 *
 * O `ThemeProvider` fornece o contexto do tema para todos os componentes filhos,
 * permitindo que o tema seja alterado dinamicamente entre "claro" e "escuro".
 * Ele usa o localStorage para persistir o tema escolhido e detecta a preferência
 * de tema do usuário por meio de `window.matchMedia`.
 *
 * @param {React.ReactNode} children - Elementos filhos que terão acesso ao contexto do tema.
 *
 * @returns {JSX.Element} - Um provedor de contexto que fornece o tema atual e uma função para alterná-lo.
 *
 * @prop {string} theme - O tema atual, que pode ser "light" (claro) ou "dark" (escuro). O valor inicial
 * é recuperado do localStorage ou baseado na preferência de tema do sistema operacional.
 * @prop {function} toggleTheme - Função que alterna o tema entre "light" e "dark".
 *
 * @example
 * // Exemplo de uso do ThemeProvider com o hook useTheme:
 * import React from 'react';
 * import { ThemeProvider, useTheme } from './ThemeProvider';
 *
 * const App = () => {
 *   const { theme, toggleTheme } = useTheme();
 *
 *   return (
 *     <div>
 *       <p>O tema atual é: {theme}</p>
 *       <button onClick={toggleTheme}>Alternar Tema</button>
 *     </div>
 *   );
 * };
 *
 * export default function Root() {
 *   return (
 *     <ThemeProvider>
 *       <App />
 *     </ThemeProvider>
 *   );
 * }
 */

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ||
      (window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light")
  );

  useEffect(() => {
    localStorage.setItem("theme", theme);
    const root = window.document.documentElement;
    if (theme === "dark") {
      root.classList.add("dark");
    } else {
      root.classList.remove("dark");
    }
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prev) => (prev === "light" ? "dark" : "light"));
  };

  const value = useMemo(() => ({ theme, toggleTheme }), [theme, toggleTheme]);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
