import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useChatContext } from "../../../../contexts/ChatContext";

const options = [
  {
    id: 1,
    title: "Projeto",
    description: "Relatório estratégico de um projeto",
    additionalInfo: "",
    value: "specific-project",
    disabled: false,
  },
  {
    id: 2,
    title: "Programa",
    description: "Relatório estratégico de um programa",
    additionalInfo: "",
    value: "specific-program",
    disabled: false,
  },
];

/**
 * Componente CardsRadioGroup.
 *
 * @component
 *
 * @description
 * O componente `CardsRadioGroup` exibe um grupo de opções, cada uma com título e descrição, dentro de cards interativos. O usuário pode selecionar uma das opções, e o componente chamará uma função de callback passada como prop para notificar o componente pai sobre a seleção. Ele utiliza o `useChatContext` para armazenar a opção selecionada.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {Function} props.onSelect - Função chamada quando o usuário seleciona uma das opções.
 *
 * @returns {JSX.Element} O componente que renderiza as opções como cards interativos.
 *
 * @example
 * const handleSelect = (value) => console.log(value);
 * return <CardsRadioGroup onSelect={handleSelect} />;
 */
export default function CardsRadioGroup({ onSelect }) {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const { setSelectedTacticalReportOption } = useChatContext();
  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setSelectedTacticalReportOption(option.value);
    onSelect(option.value); // Passa o valor selecionado para o componente pai
  };

  const gridColumns =
    options.length === 2
      ? "grid-cols-2 sm:gap-x-4 smallMobile:gap-x-2"
      : "grid-cols-1 sm:grid-cols-3 sm:gap-x-4";

  return (
    <fieldset>
      <RadioGroup
        value={selectedOption}
        onChange={handleOptionChange}
        className={`grid ${gridColumns} gap-y-6`}
      >
        {options.map((option) => (
          <RadioGroup.Option
            key={option.id}
            value={option}
            className={({ active, checked }) =>
              `relative flex cursor-pointer rounded-lg border border-slate-300 dark:border-slate-600 ${
                option.disabled
                  ? "pointer-events-none bg-slate-50 dark:bg-slate-800"
                  : "bg-defaultWhite dark:bg-slate-900"
              } p-4 shadow-sm focus:outline-none transition-colors duration-300 ${
                active
                  ? "border-indigo-600 dark:border-indigo-400 ring-2 ring-indigo-600 dark:ring-indigo-400"
                  : ""
              } ${
                checked
                  ? "border-indigo-600 text-indigo-600 dark:text-indigo-400"
                  : "text-slate-900 dark:text-slate-100"
              }`
            }
          >
            {({ checked }) => (
              <>
                <span className="flex flex-1 pb-2">
                  <span className="flex flex-col">
                    <span
                      className={`block text-sm font-semibold ${
                        option.disabled
                          ? "text-slate-400 dark:text-slate-500"
                          : ""
                      }`}
                    >
                      {option.title}
                    </span>
                    <span
                      className={`flex items-center mt-1 text-sm ${
                        option.disabled
                          ? "text-slate-500 dark:text-slate-600"
                          : ""
                      }`}
                    >
                      {option.description}
                    </span>
                    <span className="absolute text-sm font-semibold text-indigo-300/80 dark:text-indigo-300/70 top-2 right-2">
                      {option.additionalInfo}
                    </span>
                  </span>
                </span>
                {checked && (
                  <CheckCircleIcon
                    aria-hidden="true"
                    className="w-5 h-5 text-indigo-600"
                  />
                )}
                <span
                  aria-hidden="true"
                  className={`pointer-events-none absolute -inset-px rounded-lg border-2 ${
                    checked ? "border-indigo-600" : "border-transparent"
                  }`}
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </fieldset>
  );
}
