import { TableAction } from "./TableAction";
import { TableActionCell } from "./TableActionCell";
import { TableBody } from "./TableBody";
import { TableHead } from "./TableHead";
import { TableHeaderCell } from "./TableHeaderCell";
import { TableHiddenCell } from "./TableHiddenCell";
import { TableMainCell } from "./TableMainCell";
import { TableMenu } from "./TableMenu";
import { TableMenuButton } from "./TableMenuButton";
import { TableMenuItem } from "./TableMenuItem";
import { TableMenuItems } from "./TableMenuItems";
import { TablePagination } from "./TablePagination";
import { TableRoot } from "./TableRoot";
import { TableRow } from "./TableRow";
import { TableSearchButton } from "./TableSearchButton";
import { TableSearchInput } from "./TableSearchInput";

export const Table = {
  Root: TableRoot,
  Head: TableHead,
  Body: TableBody,
  Row: TableRow,
  HeaderCell: TableHeaderCell,
  MainCell: TableMainCell,
  HiddenCell: TableHiddenCell,
  ActionCell: TableActionCell,
  Action: TableAction,
  SearchButton: TableSearchButton,
  Menu: TableMenu,
  MenuButton: TableMenuButton,
  MenuItems: TableMenuItems,
  MenuItem: TableMenuItem,
  SearchInput: TableSearchInput,
  Pagination: TablePagination,
};
