import React from "react";

const DialogActions = ({ children, className = "" }) => (
  <div
    className={`mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 ${className}`}
  >
    {children}
  </div>
);

export default DialogActions;
